/*
 * Project style variables.
 */
:root {
  /* colors */
  --background-primary: #ffffff;
  --background-secondary: #f4f4f4;
  --background-accent: #92d5f7;
  --border-primary: #bfbfbf;
  --link-primary: #1890fa;
  --text-primary: #000000de;
  --text-secondary: #00000099;
  --text-charcoal: #303030;
  --text-grey: #5e5e5e;
  --text-secondary-grey: #a7a7a7;
  --blue-primary: #015e9a;
  --blue-primary-light: #1890ff1a;
  --light-blue-primary: #1890ff;
  --black-shadow: #00000029;
  --diagram-red: #d8501c;
  --diagram-gold: #f4bd40;
  --diagram-green: #82c35b;
  --coins-progress: #f6c354;
  --failed-red: #ad404d;
  --warning-yellow: #faad14;
  --orange-primary: #f5a623;
  --orange-secondary: #ef990a;
  /* Effects */
  --default-shadow: 0px 0px 10px var(--black-shadow);
  /* Dimensions */
  --max-width: 1466px;
}

/*
 * Font style definitions.
 */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/*
 * Antd global style overrides
 */

/* Antd input label */
.ant-form-vertical .ant-form-item-label,
.ant-col-24.ant-form-item-label,
.ant-col-xl-24.ant-form-item-label {
  padding-bottom: 4px;
}
.ant-form-item-label
  > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  content: none;
}

/* Put more antd overrides here... */

h1,
h2,
h3,
h4,
h5 {
  margin-top: 0;
}

div.ant-notification-notice-message {
  margin-bottom: 0 !important;
}
